<template>
    <section>
        <div class="mt-2 ml-2">
            <b-form-radio
                @change="changed"
              v-model="loa_level"
              :value="3"
              class="mt-2"
              v-if="user.external_loa_levels.loa3"
            >
              {{ loa3_text }} - LOA3
              <LoaLevel class="ml-2" :loa="3"></LoaLevel>
            </b-form-radio>
            <div v-if="user.loa_level_enabled.loa2_enabled">
              <b-form-radio
              @change="changed"
                v-model="loa_level"
                :value="2"
                class="mt-2"
                v-if="user.external_loa_levels.loa2"
              >
                {{ loa2_text }} - LOA2
                <LoaLevel class="ml-2" :loa="2"></LoaLevel>
              </b-form-radio>
            </div>
            <div v-if="user.loa_level_enabled.loa1_enabled">
              <b-form-radio
              @change="changed"
                v-model="loa_level"
                :value="1"
                class="mt-2"
                v-if="user.external_loa_levels.loa1"
              >
                {{ loa1_text }} - LOA1
                <LoaLevel class="ml-2" :loa="1"></LoaLevel>
              </b-form-radio>
            </div>
          </div>

    </section>
</template>
<script>
import LoaLevel from "../LoaLevelDescriptions.vue";
export default {
    props: {
        value: Number
    },    
    data() {
        return {
            loa_level: this.value
        };        
    },
    components: {
        LoaLevel
    },
    methods: {
        changed(){
            this.$emit('input',this.loa_level);
        }
    },
    computed: {    
        loa1_text(){
            if(this.user.loa_level_texts == undefined)
            {
                return this.$t("LOA.1")
            }else{
                if(this.user.loa_level_texts.loa1_text != "")
                {
                return this.user.loa_level_texts.loa1_text;
                } else {
                return this.$t("LOA.1");
                }
            }
        }, 
        loa2_text() {
            if (this.user.loa_level_texts == undefined) {
                return this.$t("LOA.2");
            } else {
                if (this.user.loa_level_texts.loa2_text != "") {
                return this.user.loa_level_texts.loa2_text;
                } else {
                return this.$t("LOA.2");
                }
            }
        },
        loa3_text() {
            if (this.user.loa_level_texts == undefined) {
                return this.$t("LOA.3");
            } else {
                if (this.user.loa_level_texts.loa3_text != "") {
                return this.user.loa_level_texts.loa3_text;
                } else {
                return this.$t("LOA.3");
                }
            }
        }
    },
    watch: {
        value(loa_level) {
            this.loa_level = loa_level;
        }
    },
    mounted() {
    },

}
</script>
<style>
</style>