<template>
  <section class="compose-content">
    <div v-show="!clickedItem">
      <b-form inline>
        <b-button class="no-border" @click="getItems"
          ><i class="fal fa-sync"></i
        ></b-button>

        <b-form-input
          trim
          value=""
          :placeholder="$t('SEARCH')"
          @keydown.enter.prevent
          class="searchText newline-mobile"
        ></b-form-input>
      </b-form>

      <b-table class="mt-2" striped hover :items="items" :fields="fields">
        <template v-slot:cell(id)="data">
          <b-button
            variant="dark"
            size="sm"
            @click="selectedAddress(data.item.data)"
            >{{ $t("CHOOSE") }}</b-button
          >
          <b-button variant="secondary" size="sm" @click="showMe(data.item)">{{
            $t("MORE_INFORMATION")
          }}</b-button>
        </template>

        <template v-slot:cell(data.attributes.name)="data">

         <b-button  :title="$t('SDK.REMOVE_RECIPIENT')" v-b-tooltip.hover class="no-border" @click="removeAddress(data.item)" size="sm">                
            <i class="fa-sharp fa-solid fa-bookmark"></i>
          </b-button>


          {{ data.item.data.attributes.name }}<br />{{
            data.item.data.relationships.parent.data.name
          }}
        </template>

        <template v-slot:cell(Type)="data">

          <b-button :title="$t('SDK.REMOVE_RECIPIENT')" v-b-tooltip.hover class="no-border" @click="removeAddress(data.item)" size="sm">                
            <i class="fa-sharp fa-solid fa-bookmark"></i>
          </b-button>


          {{ data.item.data.attributes.name }},
          {{ data.item.data.relationships.parent.data.name }}
          <br />
          {{ data.item.data.attributes.description }}
          <br />
          <b-button
            variant="dark"
            size="sm"
            @click="selectedAddress(data.item.data)"
            >{{ $t("CHOOSE") }}</b-button
          >
          <b-button variant="secondary" size="sm" @click="showMe(data.item)">{{
            $t("MORE_INFORMATION")
          }}</b-button>        </template>
      </b-table>

      <b-row>
        <b-col cols="12" md="6">
          <div>
            <b-form inline>
              <b-button
                v-if="selected.length != 0"
                @click="removeRecipients"
                variant="danger"
                >{{ $t("REMOVE_SELECTED") }}</b-button
              >
            </b-form>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="limit"
            align="center"
            @input="getItems"
            pills
          >
          </b-pagination>
        </b-col>
      </b-row>

      <b-button @click="saveIdentifier = true" variant="primary">{{
        $t("SDK.SAVE_WITH_IDENTIFIER")
      }}</b-button>
    </div>

    <b-modal
      id="add-sdk-with-identiier"
      v-model="saveIdentifier"
      hide-header
      @ok="cancelSave"
      centered
      ok-only
      ok-variant="secondary"
      :ok-title="$t('CANCEL')"
    >
      <b-form-input
        trim
        v-model="search_identifier"
        :placeholder="$t('SDK.IDENTIFIER')"
      ></b-form-input>
      <b-form-input
        trim
        class="mt-2"
        v-model="search_organisation"
        :placeholder="$t('ORGANISATION.TITLE')"
      ></b-form-input>
      <b-button
        class="mt-2"
        @click="searchReplyIdentifier"
        :disabled="search_identifier == ''"
        variant="primary"
        >{{ $t("FETCH_INFORMATION") }}</b-button
      >

      <b-table
        v-if="searchItems.length != 0"
        class="mt-2"
        striped
        hover
        :items="searchItems"
        :fields="searchFields"
      >
        <template v-slot:cell(Type)="data">
          {{ data.item.attributes.name }},
          {{ data.item.relationships.parent.data.name }}
          <br />
          <b-button variant="dark" size="sm" @click="saveAddress(data.item)"><i class="fa-sharp fa-regular fa-bookmark"></i> {{
            $t("SAVE")
          }}</b-button>
        </template>

        <template v-slot:cell(id)="data">
          <b-button variant="dark" size="sm" @click="saveAddress(data.item)"><i class="fa-sharp fa-regular fa-bookmark"></i> {{
            $t("SAVE")
          }}</b-button>
        </template>
      </b-table>

      <div v-if="searchDone && searchItems.length == 0">
        {{ $t("SDK.ADD_NOT_FOUND_SEARCH") }}
      </div>
    </b-modal>

    <div v-if="clickedItem">
      <SDKInformation
        :item="clickedItem"
        :functionbox_uuid="functionbox_uuid"
        @setItem="setItem"
        @selectedAddress="selectedAddress"
        @removedAddress="removedAddress"
        @backBtn="backBtn"
      ></SDKInformation>
    </div>
  </section>
</template>
<script>
import SDKInformation from "../../Input/SDK/SdkInformation";
export default {
  components: {
    SDKInformation,
  },
  props: ["functionbox_uuid"],
  data() {
    return {
      fields: [
        {
          key: "data.attributes.name",
          label: "",
          class: "hidden-mobile",
          thClass: "w-350",
        },
        {
          key: "data.attributes.description",
          label: "",
          class: "hidden-mobile",
          thClass: "w-350",
        },
        {
          key: "Id",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "w-300",
        },
        {
          key: "Type",
          label: "",
          class: "text-break clickable hidden-not-mobile",
          thClass: "w-300 hidden-mobile",
        },
      ],
      searchFields: [
        {
          key: "attributes.name",
          label: "",
          class: "hidden-mobile",
          thClass: "w-350",
        },
        {
          key: "relationships.parent.data.name",
          label: "",
          class: "hidden-mobile",
          thClass: "w-350",
        },
        {
          key: "Id",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "w-300",
        },
        {
          key: "Type",
          label: "",
          class: "text-break clickable hidden-not-mobile",
          thClass: "w-300 hidden-mobile",
        },
      ],
      searchItems: [],
      saveIdentifier: false,
      search_identifier: "",
      search_organisation: "",
      identifierValid: false,
      filterLimit: 10,
      currentFilterPage: 1,
      tabindex: 0,
      selected: [],
      currentPage: 1,
      limit: 6,
      loading: false,
      items: [],
      to_sdk: null,
      clickedItem: null,
      searchDone: false,
    };
  },
  methods: {
    async removeAddress(adress) {
      let self = this;
      self.loading = true;
      let id_list = [];
      id_list.push(adress.id);
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function (value) {
          if (value) {
            let RemoveSdkSefosAddressDTO = {
              id_list: id_list,
              functionbox_uuid: self.functionbox_uuid,
            };
            await self.$http.post(self.user.hostname + "/sdk/address/remove",RemoveSdkSefosAddressDTO);
            //self.$noty.info(self.$t("REMOVED"));
            self.getItems();              
          }
        })
        .catch(function () {});
    },
    async clearModal() {
      this.saveIdentifier = false;
      this.search_identifier = "";
      this.search_organisation = "";
      this.searchDone = false;
      this.searchItems = [];
      this.to_sdk = null;
    },
    async saveAddress(item) {
      let self = this;
      let AddSdkSefosAddressDTO = {
        functionbox_uuid: this.functionbox_uuid,
        sdk_identifier: item.attributes.identifier,
        sdk_organisation: item.relationships.parent.data.identifier,
      };
      await this.$http
        .post(this.user.hostname + "/sdk/address/add", AddSdkSefosAddressDTO)
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.getItems();
          self.clearModal();
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    cancelSave() {
      this.clearModal();
    },
    async searchReplyIdentifier() {
      let self = this;
      let SdkIdentifierDTO = {
        functionbox_uuid: self.functionbox_uuid,
        identifier: this.search_identifier,
        organisation: this.search_organisation,
        name: this.search_organisation,
        filterSaved: true,
      };
      self.loading = true;
      await this.$http
        .post(this.user.hostname + "/sdk/address/identifier", SdkIdentifierDTO)
        .then(function (result) {
          //self.searchDone = true;
          self.searchItems = result.data;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    setItem(item) {
      this.clickedItem = item;
    },
    removedAddress() {
      this.getItems();
    },
    backBtn() {
      this.clickedItem = null;
    },
    showMe(item) {
      this.clickedItem = item;
    },
    selectedAddress(item) {
      this.$emit("selectedAddress", item);
    },
    async removeRecipients() {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function (value) {
          if (value) {
            let RemoveSdkSefosFilterDTO = {
              id_list: self.selected,
              functionbox_uuid: self.functionbox_uuid,
            };
            await self.$http
              .post(
                self.user.hostname + "/sdk/address/remove",
                RemoveSdkSefosFilterDTO
              )
              .then(function () {
                self.$noty.info(self.$t("REMOVED"));
                self.getSavedFilters();
                self.loading = false;
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    cancelBtn() {
      this.$emit("cancel");
    },
    async getItems() {
      let self = this;
      self.loading = true;
      await this.$http
        .get(this.user.hostname + "/sdk/address/" + this.functionbox_uuid)
        .then(function (result) {
          self.items = result.data;
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {},
  mounted() {
    this.getItems();
  },
};
</script>
<style></style>
