<template>
  <section v-if="message_type">

      <SearchRecipients
        :message_type="getMessageType"
        :user_uuid="user_uuid"
        :key="'forceRedrawRecipientsKey_' + forceRedrawRecipientsIndex"
        :functionbox_uuid="functionbox_uuid"
        :isNumber="isNumber"
        :singleSelect="singleSelect"
        v-model="addresses"
        @IsSearching="IsSearching"
        @IsTyping="IsTyping"
        @forceRedraw="forceRedrawRecipientsIndex++"
      ></SearchRecipients>
      
  </section>
</template>
<script>
import SearchRecipients from "../Compose/SearchRecipients";
export default {
  components: {
    SearchRecipients,
  },
  props: {
    message_uuid: {
      default: "",
      type: String
    },
    functionbox_uuid: {
      default: "",
      type: String
    },
    user_uuid: {
      default: "",
      type: String
    },
    message_type: {
      default: "",
      type: String
    },
    participants: {
      default: () => [],
      type: Array,
    },
    external_participants: {
      default: () => [],
      type: Array,
    },
    isNumber: {
      default: false,
      type: Boolean
    },
    isMeeting: {
      default: false,
      type: Boolean
    },
    singleSelect: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      updating: false,
      isSearching: false,
      addresses: [],
      forceRedrawRecipientsIndex: 0,
    };
  },
  methods: {
    IsTyping(val){
      return this.$emit('IsTyping',val);
    },
    async GetExternalParticipantList(notregisteredList) {
      var list = [];
      if(this.message_uuid != "")
      {
        let externalAlreadyInConversation = [];
        await this.$http
          .post(this.user.hostname + "/message/conversation/emails", {
            message_uuid: this.message_uuid,
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,
            emails: this.notregisteredList,
          })
          .then(function (result) {
            externalAlreadyInConversation = result.data;
          })
          .catch(function () {});
        for (const notregistered of notregisteredList) {
          if (externalAlreadyInConversation.includes(notregistered)) {
            list.push({
              email: notregistered,
              authentication_method: "conversation",
              authentication_identifier: "",
              language: this.$i18n.locale,
              validated: true,
              showSefos: false
            });
          } else {
            list.push({
              email: notregistered,
              authentication_method: "NOT_SET",
              authentication_identifier: "",
              language: this.$i18n.locale,
              validated: false,
              showSefos: false
            });
          }
        }
      }else{
        for (const notregistered of notregisteredList) {
          list.push({
              email: notregistered,
              authentication_method: "NOT_SET",
              authentication_identifier: "",
              language: this.$i18n.locale,
              validated: false,
              showSefos: false
            });
        }
      }
      return list;
    },
    async UpdatedAddresses(added, removed) {
      if(!this.updating)
      {
        this.updating = true;
        var external_participants = this.external_participants;
        var participants = this.participants;
        
        let result = await this.$http.post(
          this.user.hostname + "/sefos-message/verify/emails",
          {
            organisation_uuid:
              this.user.information.organisation.organisation_uuid,
            functionbox_uuid: this.functionbox_uuid,
            emails: added,
          }
        );
        if(this.isMeeting)
        {
          if(result.data.registered.length > 0)
          {
            let resultCheckRegistered = await this.$http.post(
              this.user.hostname + "/meeting/check-registered",
              {
                emails: result.data.registered,
              }
            );
            if (resultCheckRegistered.data.registered.length > 0) {
              for (const email of resultCheckRegistered.data.registered) {
                let found = external_participants.filter(function(searchItem) { return searchItem.email == email; }).length != 0;
                if(!found)
                {
                  external_participants.push({
                    email: email,
                    authentication_method: "sefos-account",
                    authentication_identifier: "",
                    language: this.$i18n.locale,
                    validated: true,
                    showSefos: true
                  });
                }
              }
            }
            if (resultCheckRegistered.data.notregistered.length > 0) {
              let newList = await this.GetExternalParticipantList(resultCheckRegistered.data.notregistered);
              for (const item of newList) {
                let found = external_participants.filter(function(searchItem) { return searchItem.email == item.email; }).length != 0;
                if(!found)
                {
                  external_participants.push(item);
                }
              }
            }
          }
        }else{
          if (result.data.registered.length > 0) {
            for (const email of result.data.registered) {
              let found = participants.filter(function(searchItem) { return searchItem.email == email; }).length != 0;
              if(!found)
              {
                participants.push({ email: email });
              }
            }
          }
        }
        if (result.data.notregistered.length > 0) {
          let newList = await this.GetExternalParticipantList(result.data.notregistered);
          for (const item of newList) {
            let found = external_participants.filter(function(searchItem) { return searchItem.email == item.email; }).length != 0;
            if(!found)
            {
              external_participants.push(item);
            }
          }
        }
        participants = participants.filter((item) => !removed.includes(item.email));  
        external_participants = external_participants.filter((item) => !removed.includes(item.email));
        this.$emit("UpdatedParticipants", { participants: participants, external_participants: external_participants })
        this.updating = false;
      }
    },
    IsSearching(isSearching) {
      this.$emit('IsSearching',isSearching);
      this.isSearching = isSearching;
    },
  },
  computed: {
    getMessageType(){
      if(this.message_type == 'fax-message')
      {
        return 'fax-messsage';
      }
      return "message";
    },
    currentParticipants() {
      let external_participants = this.external_participants.map(
        (a) => a.email
      );
      let participants = this.participants.map((a) => a.email);
      return participants.concat(external_participants);
    },
  },
  watch: {
    addresses(newAddresses, oldAddresses) {      
      var added = newAddresses.filter((address) => !oldAddresses.includes(address));
      var removed = oldAddresses.filter((address) => !newAddresses.includes(address));      
      if(added.length != 0 || removed.length != 0)
      {
        this.UpdatedAddresses(added, removed);
      }
    },
  },
  mounted() {
    this.addresses = this.currentParticipants;    
    this.forceRedrawRecipientsIndex++;
  },
};
</script>
<style>
</style>