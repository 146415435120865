var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-2 mb-2" },
      [
        _c("b-form-textarea", {
          staticClass: "textarea",
          attrs: {
            id: "textarea",
            state: _vm.TextState,
            rows: _vm.rows,
            disabled: _vm.settings.email_body != "",
            placeholder: _vm.$t("MESSAGES.EXTERNAL_TEXT_PLACEHOLDER"),
            "max-rows": _vm.rows,
          },
          model: {
            value: _vm.external_text,
            callback: function ($$v) {
              _vm.external_text = $$v
            },
            expression: "external_text",
          },
        }),
        _c("div", { staticClass: "clearfix" }, [
          _c(
            "div",
            { staticClass: "float-left" },
            [
              _vm.settings.email_body == ""
                ? _c("b-form-text", { attrs: { id: "email_body" } }, [
                    !_vm.isMeeting
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("MESSAGES.EXTERNAL_TEXT_INFORMATION"))
                          ),
                        ])
                      : _vm._e(),
                    _vm.isMeeting
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "MESSAGES.EXTERNAL_TEXT_INFORMATION_MEETING"
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                    _c("br"),
                    _vm._v(
                      _vm._s(_vm.$t("MESSAGES.EXTERNAL_TEXT_INFORMATION_SMS")) +
                        "\n  "
                    ),
                  ])
                : _vm._e(),
              _vm.external_text.length > _vm.limit
                ? _c("div", { staticClass: "error-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm.settings.email_body != "" && _vm.settings.unlock_email_body
                ? _c(
                    "b-button",
                    {
                      staticClass: "no-border mt-2 mb-2",
                      attrs: { size: "sm" },
                      on: { click: _vm.unlockEmailBody },
                    },
                    [
                      _c("i", { staticClass: "fal fa-unlock" }),
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("BTN_UNLOCK_EMAIL_BODY"))
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "float-right" }, [
            _c("div", { staticClass: "text-right" }, [
              _c("small", { staticClass: "pull-right" }, [
                _vm._v(
                  _vm._s(_vm.external_text.length) + "/" + _vm._s(_vm.limit)
                ),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }