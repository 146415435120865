var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("i", {
        staticClass: "clickable fal fa-info-square",
        on: {
          click: function ($event) {
            _vm.show = true
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "loa-information",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            "body-class": "loa-information",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "text-left" }, [
            _c("b", [
              _vm._v("LOA - Level of assurance\n          "),
              _c("div", { staticClass: "mt-2" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("LOA.SHORT_DESCRIPTION")) +
                    "\n          "
                ),
              ]),
              _vm.loa != 1
                ? _c("div", { staticClass: "mt-2" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("LOA.APPROVED")) +
                        " \n          "
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt-2" }, [
                _vm._v(
                  "\n              LOA, " +
                    _vm._s(_vm.$t("LOA.NAME")) +
                    " " +
                    _vm._s(_vm.loa) +
                    "\n          "
                ),
              ]),
            ]),
            _vm.loa == 1
              ? _c("div", { staticClass: "mt-2" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("LOA.LEVEL1")) + "\n      "
                  ),
                ])
              : _vm._e(),
            _vm.loa != 1
              ? _c("div", { staticClass: "mt-2" }, [
                  _c("ul", [
                    _vm.loa == 2
                      ? _c("li", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.sms")))])
                      : _vm._e(),
                    _vm.loa == 2
                      ? _c("li", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.sefos")))])
                      : _vm._e(),
                    _vm.loa >= 2
                      ? _c("li", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("AUTH_METHODS.se-eid")) +
                              "\n            "
                          ),
                          _c("ul", [
                            _c("li", [
                              _vm._v(_vm._s(_vm.$t("AUTH_METHODS.freja"))),
                            ]),
                            _c("li", [
                              _vm._v(_vm._s(_vm.$t("AUTH_METHODS.bankid-se"))),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.loa >= 2
                      ? _c("li", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.siths")))])
                      : _vm._e(),
                    _vm.loa >= 2
                      ? _c("li", [
                          _vm._v(_vm._s(_vm.$t("AUTH_METHODS.freja-org"))),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }