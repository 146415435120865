var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "info-header mt-2" },
        [
          _vm._t("default"),
          _vm.showAddRemove
            ? _c(
                "b-button",
                {
                  staticClass: "ml-2 no-border",
                  attrs: { size: "sm" },
                  on: { click: _vm.showAdd },
                },
                [
                  _c("i", { staticClass: "fal fa-plus" }),
                  _vm._v(" " + _vm._s(_vm.$t("ADD"))),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("SdkReferensId", {
        attrs: { "show-add-remove": "", items: _vm.items },
        on: { removeItem: _vm.removeItem },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header-close": "",
            "ok-disabled": _vm.okDisabled,
            title: _vm.extensionCaption,
            centered: "",
            "cancel-title": _vm.$t("CANCEL"),
            "ok-title": _vm.$t("ADD"),
          },
          on: { cancel: _vm.cancelSave, ok: _vm.okSave },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-1",
                label: _vm.labelCaption,
                "label-for": "input-2",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  trim: "",
                  "aria-describedby": "label-help-block",
                  placeholder: "",
                },
                model: {
                  value: _vm.label,
                  callback: function ($$v) {
                    _vm.label = $$v
                  },
                  expression: "label",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-2",
                label: _vm.rootCaption,
                "label-for": "input-2",
              },
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.root,
                  callback: function ($$v) {
                    _vm.root = $$v
                  },
                  expression: "root",
                },
              }),
            ],
            1
          ),
          _vm.root != ""
            ? _c(
                "b-form-group",
                { attrs: { id: "input-3", "label-for": "input-2" } },
                [
                  _vm.showRegular
                    ? _c("b-form-input", {
                        attrs: {
                          trim: "",
                          placeholder: "",
                          "aria-describedby": "extension-help-block",
                        },
                        model: {
                          value: _vm.extension,
                          callback: function ($$v) {
                            _vm.extension = $$v
                          },
                          expression: "extension",
                        },
                      })
                    : _vm._e(),
                  _vm.root == "1.2.752.129.2.1.3.1"
                    ? _c("Pnr", {
                        attrs: { required: "" },
                        on: { valid: _vm.setValid },
                        model: {
                          value: _vm.extension,
                          callback: function ($$v) {
                            _vm.extension = $$v
                          },
                          expression: "extension",
                        },
                      })
                    : _vm._e(),
                  _c("b-form-text", { attrs: { id: "extension-help-block" } }, [
                    _vm._v(_vm._s(_vm.extensionDescription)),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }