var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message_type
    ? _c(
        "section",
        [
          _c("SearchRecipients", {
            key: "forceRedrawRecipientsKey_" + _vm.forceRedrawRecipientsIndex,
            attrs: {
              message_type: _vm.getMessageType,
              user_uuid: _vm.user_uuid,
              functionbox_uuid: _vm.functionbox_uuid,
              isNumber: _vm.isNumber,
              singleSelect: _vm.singleSelect,
            },
            on: {
              IsSearching: _vm.IsSearching,
              IsTyping: _vm.IsTyping,
              forceRedraw: function ($event) {
                _vm.forceRedrawRecipientsIndex++
              },
            },
            model: {
              value: _vm.addresses,
              callback: function ($$v) {
                _vm.addresses = $$v
              },
              expression: "addresses",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }