<template>

  <section>
    <b-form-input
    trim
    class="mt-2 pl-2"
    input-id="subject"
    id="subject"
    :disabled="settings.subject != ''"
    :placeholder="$t('SUBJECT')"
    :state="TextState"
    autocomplete="off"
    v-model="subject"
  ></b-form-input>

  <div class="clearfix">
    <div class="float-left">
      <div class="error-text" v-if="subject.length > limit">
        {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
      </div>
      <b-form-text id="subject" v-if="settings.subject == ''">
        {{ $t("SUBJECT_EMAIL") }}
      </b-form-text>
      <b-button
        v-if="settings.subject != '' && settings.unlock_subject"
        @click="unlockSubject"
        size="sm"
        class="no-border mt-2 mb-2"
        ><i class="fal fa-unlock"></i>
        {{ $t("BTN_UNLOCK_SUBJECT") }}</b-button
      >
    </div>
    <div class="float-right">
      <div class="text-right">
        <small class="pull-right"
          >{{ subject.length }}/{{limit}}</small
        >
      </div>
    </div>
  </div>

  </section>
</template>
<script>
export default {
  props: {
    value: String,
    settings: {
      default: null,
      type: Object
    },
    limit: {
      default: 256,
      type: Number
    }
  },
  data() {
    return {
      subject: ''
    };
  },
  methods: {
    unlockSubject() {
      this.settings.subject = "";
      this.settings.unlock_subject = 0;
    },
  },
  computed: {
    TextState(){
      if(this.subject == "")
      {
        return false;
      }
      return this.subject.length == 0 ? null : this.subject.length <= this.limit;
    }
  },
  watch: {
    subject() {
      this.$emit('valid', this.subject.length <= this.limit);
      this.$emit('input', this.subject);
    }
  },
  mounted() {
    this.subject = this.value;
  },
};
</script>
