<template>

  <div>    
    <div class="mt-2 mb-2">
    
      <b-form-textarea
        class="textarea"
        id="textarea"
        v-model="external_text"
        :state="TextState"
        :rows="rows"
        :disabled="settings.email_body != ''"
        :placeholder="$t('MESSAGES.EXTERNAL_TEXT_PLACEHOLDER')"        
        :max-rows="rows"
      ></b-form-textarea>
      <div class="clearfix">
        <div class="float-left">
          
      <b-form-text
      id="email_body"
      v-if="settings.email_body == ''"
    >
      <span v-if="!isMeeting">{{ $t("MESSAGES.EXTERNAL_TEXT_INFORMATION") }}</span>
      <span v-if="isMeeting">{{ $t("MESSAGES.EXTERNAL_TEXT_INFORMATION_MEETING") }}</span>
      <br />{{ $t("MESSAGES.EXTERNAL_TEXT_INFORMATION_SMS") }}
    </b-form-text>
    
          <div
            class="error-text"
            v-if="external_text.length > limit"
          >
            {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
          </div>        
          <b-button
            v-if="
              settings.email_body != '' && settings.unlock_email_body
            "
            @click="unlockEmailBody"
            size="sm"
            class="no-border mt-2 mb-2"
            ><i class="fal fa-unlock"></i>
            {{ $t("BTN_UNLOCK_EMAIL_BODY") }}</b-button
          >
        </div>
        <div class="float-right">
          <div class="text-right">
            <small class="pull-right"
              >{{ external_text.length }}/{{limit}}</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    settings: {
      default: null,
      type: Object
    },
    limit: {
      default: 1500,
      type: Number
    },
    rows: {
      default: 3,
      type: Number,
    },
    isMeeting: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      external_text: ''
    };
  },
  methods: {
    unlockEmailBody() {
      this.settings.email_body = "";
      this.settings.unlock_email_body = 0;
    },
  },
  computed: {
    TextState(){
      return this.external_text.length == 0 ? null : this.external_text.length <= this.limit;
    }
  },
  watch: {
    external_text() {
      this.$emit('valid', this.external_text.length <= this.limit);
      this.$emit('input', this.external_text);
    }
  },
  mounted() {
    this.external_text = this.value;
  },
};
</script>
