<template>
  <section v-if="details">
    <div class="info-header mt-2">{{ $t("INFORMATION") }}</div>

    <div class="clearfix">
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-td class="w-150">{{ $t("NAME") }}</b-td>
            <b-td>{{ details.attributes.name }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("DESCRIPTION") }}</b-td>
            <b-td>{{ details.attributes.description }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("IDENTIFIER") }}</b-td>
            <b-td>{{ details.attributes.identifier }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("ORGANISATION.TITLE") }}</b-td>
            <b-td>{{ details.relationships.parent.data.name }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("ORGANISATION.TITLE") }} Id</b-td>
            <b-td>{{ details.relationships.parent.data.identifier }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("ID") }}</b-td>
            <b-td>{{ details.id }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <div v-if="details.attributes.categories">
      <div v-if="details.attributes.categories.length != 0">
        <div class="info-header mt-2">{{ $t("SDK.CATEGORIES") }}</div>
        <div class="clearfix" v-if="details.attributes.categories">
          <b-table-simple>
            <b-tbody>
              <b-tr
                v-for="(item, index) in details.attributes.categories"
                :key="index"
              >
                <b-td>{{ item }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>

    <div class="clearfix" v-if="details.attributes.codes">
      <div v-if="details.attributes.codes.length != 0">
        <div class="info-header mt-2">{{ $t("SDK.CODES") }}</div>
        <div v-if="1 == 0">{{ $t("NO_CONTENT") }}</div>
        <b-table-simple>
          <b-tbody>
            <b-tr
              v-for="(item, index) in details.attributes.codes"
              :key="index"
            >
              <b-td class="w-150">{{ item.coding.code }}</b-td>
              <b-td>{{ item.coding.displayText }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>

    <div class="clearfix" v-if="details.attributes.municipalityCode">
      <div v-if="details.attributes.municipalityCode.length != 0">
        <div class="info-header mt-2">{{ $t("SDK.MUNICIPALITIES") }}</div>
        <b-table-simple>
          <b-tbody>
            <b-tr
              v-for="(item, index) in details.attributes.municipalityCode"
              :key="index"
            >
              <b-td class="w-150">{{ item.coding.code }}</b-td>
              <b-td>{{ item.coding.displayText }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>        
    </div>

    <div class="clearfix" v-if="details.attributes.regionCode">
      <div v-if="details.attributes.regionCode.length != 0">
        <div class="info-header mt-2">{{ $t("SDK.REGIONS") }}</div>
        <b-table-simple>
          <b-tbody>
            <b-tr
              v-for="(item, index) in details.attributes.regionCode"
              :key="index"
            >
              <b-td class="w-150">{{ item.coding.code }}</b-td>
              <b-td>{{ item.coding.displayText }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>

    <div class="mt-2 mb-2" v-if="loaded_address">
      <b-button @click="selectedAddress" size="sm" variant="dark" v-if="!hideSelect">{{
        $t("CHOOSE")
      }}</b-button>

      <b-button @click="saveAddress" size="sm" v-if="!hideSave && show_save" variant="primary"><i class="fa-sharp fa-regular fa-bookmark"></i> {{
        $t("SDK.SAVE_RECIPIENT")
      }}</b-button>

      <b-button @click="removeAddress" size="sm" v-if="!hideRemove && !show_save" variant="danger">
        <i class="fa-sharp fa-solid fa-bookmark"></i> {{
        $t("SDK.REMOVE_RECIPIENT")
      }}</b-button>

      <b-button @click="backBtn" size="sm"  variant="secondary" v-if="!hideBack">{{ $t("BACK") }}</b-button>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    hideSelect: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    hideSave: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    hideRemove: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    hideBack: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    item: {
      default: null,
      type: Object,
    }
  },
  data() {
    return {
      currentId: 0,
      info: this.item,
      loading: false,
      details: null,
      saved_address: [],
      loaded_address: false
    };
  },
  methods: {
    async init() {
      if (this.info != null) {
        if (this.info.functionbox_uuid == undefined) {
          this.details = this.info;
          this.currentId = 0;
        } else {
          this.currentId = this.info.id;
          this.details = this.info.data;
        }
      }
      this.getSavedAddresses();
    },
    async getSavedAddresses() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/sdk/address/" + this.functionbox_uuid)
        .then(function (result) {
          for(let ix = 0;ix < result.data.length; ix++)
          {
            if(result.data[ix].sdk_identifier == self.details.attributes.identifier)
            {
              self.currentId = result.data[ix].id;
            }
          }
          self.saved_address = result.data;
          self.loaded_address = true;
        })
        .catch(function () { self.loaded_address = true; });
    },
    async saveAddress() {
      let self = this;
      let AddSdkSefosAddressDTO = {
        functionbox_uuid: this.functionbox_uuid,
        sdk_organisation: this.item.relationships.parent.data.identifier,
        sdk_identifier: this.item.attributes.identifier,
      };
      await this.$http
        .post(this.user.hostname + "/sdk/address/add", AddSdkSefosAddressDTO)
        .then(function (response) {
          self.currentId = response.data.id;
          self.getSavedAddresses();
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    async removeAddress() {
      let self = this;
      self.loading = true;
      let id_list = [];
      id_list.push(this.currentId);
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function (value) {
          if (value) {
            let RemoveSdkSefosAddressDTO = {
              id_list: id_list,
              functionbox_uuid: self.functionbox_uuid,
            };
            await self.$http
              .post(
                self.user.hostname + "/sdk/address/remove",
                RemoveSdkSefosAddressDTO
              )
              .then(function () {
                //self.$noty.info(self.$t("REMOVED"));
                if (self.info.functionbox_uuid != undefined) {
                  //self.$emit("removedAddress");
                  self.backBtn();
                } else {
                  self.getSavedAddresses();
                }
                self.loading = false;
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    selectedAddress() {
      this.$emit("selectedAddress", this.details);
    },
    backBtn() {
      this.$emit("backBtn");
    },
    unselectAddress() {
      this.$emit("unselectAddress");
    },
  },
  computed: {
    show_save() {
      let self = this;
      return (
        this.saved_address.filter(function (item) {
          return item.sdk_identifier == self.details.attributes.identifier;
        }).length == 0
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style></style>
