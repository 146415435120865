var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "compose-content" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.clickedItem,
              expression: "!clickedItem",
            },
          ],
        },
        [
          _c(
            "b-tabs",
            {
              attrs: { "content-class": "mt-2" },
              model: {
                value: _vm.tabindex,
                callback: function ($$v) {
                  _vm.tabindex = $$v
                },
                expression: "tabindex",
              },
            },
            [
              _c(
                "b-tab",
                { attrs: { title: _vm.$t("SEARCH") } },
                [
                  _c(
                    "b-form",
                    { key: _vm.filterKey, attrs: { inline: "" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "no-border",
                          on: { click: _vm.searchAddress },
                        },
                        [_c("i", { staticClass: "fal fa-sync" })]
                      ),
                      _c("FilterInput", {
                        attrs: { title: _vm.$t("NAME") },
                        model: {
                          value: _vm.filter_name,
                          callback: function ($$v) {
                            _vm.filter_name = $$v
                          },
                          expression: "filter_name",
                        },
                      }),
                      _c("FilterText", {
                        attrs: { title: _vm.$t("DESCRIPTION") },
                        model: {
                          value: _vm.filter_description,
                          callback: function ($$v) {
                            _vm.filter_description = $$v
                          },
                          expression: "filter_description",
                        },
                      }),
                      _c("FilterItems", {
                        attrs: {
                          title: _vm.$t("FILTER.SDK.CODE"),
                          filterData: _vm.filter_code_data,
                        },
                        model: {
                          value: _vm.filter_code,
                          callback: function ($$v) {
                            _vm.filter_code = $$v
                          },
                          expression: "filter_code",
                        },
                      }),
                      _c("FilterItems", {
                        attrs: {
                          title: _vm.$t("FILTER.SDK.CATEGORY"),
                          filterData: _vm.filter_category_data,
                        },
                        model: {
                          value: _vm.filter_category,
                          callback: function ($$v) {
                            _vm.filter_category = $$v
                          },
                          expression: "filter_category",
                        },
                      }),
                      _c("FilterItems", {
                        attrs: {
                          title: _vm.$t("FILTER.SDK.MUNICIPALITY"),
                          filterData: _vm.filter_municipality_data,
                        },
                        model: {
                          value: _vm.filter_municipality,
                          callback: function ($$v) {
                            _vm.filter_municipality = $$v
                          },
                          expression: "filter_municipality",
                        },
                      }),
                      _c("FilterItems", {
                        attrs: {
                          title: _vm.$t("FILTER.SDK.REGION"),
                          filterData: _vm.filter_region_data,
                        },
                        model: {
                          value: _vm.filter_region,
                          callback: function ($$v) {
                            _vm.filter_region = $$v
                          },
                          expression: "filter_region",
                        },
                      }),
                      _c("FilterCustom", {
                        attrs: { title: _vm.$t("FILTER.SDK.CUSTOM") },
                        model: {
                          value: _vm.filter_custom,
                          callback: function ($$v) {
                            _vm.filter_custom = $$v
                          },
                          expression: "filter_custom",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-navbar",
                    { staticClass: "navbar-internal-menu mb-0 pb-0 mt-0 pt-0" },
                    [
                      _c(
                        "b-navbar-nav",
                        [
                          !_vm.currentFilter
                            ? _c("b-nav-item", { attrs: { href: "#" } }, [
                                _vm._v(_vm._s(_vm.$t("SELECTED")) + ":"),
                              ])
                            : _vm._e(),
                          _vm.currentFilter
                            ? _c("b-nav-item", { attrs: { href: "#" } }, [
                                _vm._v(_vm._s(_vm.currentFilter.name) + ":"),
                              ])
                            : _vm._e(),
                          _c(
                            "b-nav-text",
                            {
                              key: _vm.filter_all_data.length,
                              staticClass: "nav-tags",
                            },
                            _vm._l(_vm.filter_all_data, function (item, key) {
                              return _c(
                                "span",
                                { key: key, staticClass: "tag" },
                                [
                                  _vm._v(_vm._s(item.text) + " "),
                                  _c("i", {
                                    staticClass: "clickable fal fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeTag(item)
                                      },
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-navbar-nav",
                        { staticClass: "ml-auto" },
                        [
                          !_vm.currentFilter
                            ? _c(
                                "b-nav-item-dropdown",
                                {
                                  staticClass: "m-md-2 pull-right",
                                  attrs: {
                                    right: "",
                                    id: "dropdown-1",
                                    text: _vm.$t("MANAGE"),
                                    "toggle-class": "no-border",
                                  },
                                },
                                [
                                  _vm.hasFilter
                                    ? _c(
                                        "b-dropdown-item",
                                        { on: { click: _vm.clearFilterAll } },
                                        [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR")))]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.save-as-new",
                                          modifiers: { "save-as-new": true },
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("FILTER.SDK.SAVE_NEW"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentFilter
                            ? _c(
                                "b-nav-item-dropdown",
                                {
                                  staticClass: "m-md-2",
                                  attrs: {
                                    right: "",
                                    id: "dropdown-1",
                                    text: _vm.$t("MANAGE"),
                                    "toggle-class": "no-border",
                                  },
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    { on: { click: _vm.clearFilterAll } },
                                    [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR")))]
                                  ),
                                  _vm.currentFilter
                                    ? _c(
                                        "b-dropdown-item",
                                        { on: { click: _vm.UpdateFiler } },
                                        [_vm._v(_vm._s(_vm.$t("SAVE")))]
                                      )
                                    : _vm._e(),
                                  _vm.currentFilter
                                    ? _c("b-dropdown-divider")
                                    : _vm._e(),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.save-as-new",
                                          modifiers: { "save-as-new": true },
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("FILTER.SDK.SAVE_NEW"))
                                      ),
                                    ]
                                  ),
                                  _vm.currentFilter
                                    ? _c(
                                        "b-dropdown-item",
                                        { on: { click: _vm.removeFilter } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("FILTER.SDK.REMOVE"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mb-2" }),
                  _vm.items
                    ? _c(
                        "div",
                        [
                          _c("b-table", {
                            attrs: {
                              striped: "",
                              hover: "",
                              items: _vm.filteredAddressItems,
                              "per-page": _vm.perPage,
                              "current-page": _vm.currentPage,
                              fields: _vm.fields,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(type)",
                                  fn: function (data) {
                                    return [
                                      !_vm.verifySavedAddress(data.item)
                                        ? _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass: "no-border",
                                              attrs: {
                                                title:
                                                  _vm.$t("SDK.SAVE_RECIPIENT"),
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveAddress(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-sharp fa-regular fa-bookmark",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.verifySavedAddress(data.item)
                                        ? _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass: "no-border",
                                              attrs: {
                                                title: _vm.$t(
                                                  "SDK.REMOVE_RECIPIENT"
                                                ),
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAddress(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-sharp fa-solid fa-bookmark",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n\n            " +
                                          _vm._s(data.item.attributes.name) +
                                          ", " +
                                          _vm._s(
                                            data.item.relationships.parent.data
                                              .name
                                          ) +
                                          "\n            "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            data.item.attributes.description
                                          ) +
                                          "\n            "
                                      ),
                                      _c("br"),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectedAddress(
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("CHOOSE")))]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "secondary",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showMe(data.item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("MORE_INFORMATION"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(id)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectedAddress(
                                                data.item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("CHOOSE")))]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "secondary",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showMe(data.item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("MORE_INFORMATION"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(attributes.name)",
                                  fn: function (data) {
                                    return [
                                      !_vm.verifySavedAddress(data.item)
                                        ? _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass: "no-border",
                                              attrs: {
                                                title:
                                                  _vm.$t("SDK.SAVE_RECIPIENT"),
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveAddress(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-sharp fa-regular fa-bookmark",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.verifySavedAddress(data.item)
                                        ? _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass: "no-border",
                                              attrs: {
                                                title: _vm.$t(
                                                  "SDK.REMOVE_RECIPIENT"
                                                ),
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAddress(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-sharp fa-solid fa-bookmark",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n\n              " +
                                          _vm._s(data.item.attributes.name)
                                      ),
                                      _c("p", { staticClass: "mt-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            data.item.relationships.parent.data
                                              .name
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1659176044
                            ),
                          }),
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.filteredAddressItems.length,
                              "per-page": _vm.perPage,
                              "aria-controls": "my-table",
                            },
                            model: {
                              value: _vm.currentPage,
                              callback: function ($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: _vm.$t("FILTER.SDK.SAVED_FILTERS") } },
                [
                  _c(
                    "div",
                    { key: _vm.filter_list.length },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "no-border",
                          on: { click: _vm.getSavedFilters },
                        },
                        [_c("i", { staticClass: "fal fa-sync" })]
                      ),
                      _c("b-table", {
                        attrs: {
                          striped: "",
                          hover: "",
                          "per-page": _vm.filterLimit,
                          items: _vm.filter_list,
                          fields: _vm.fieldsFilter,
                        },
                        on: { "row-clicked": _vm.SetFilter },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(id)",
                            fn: function (data) {
                              return [
                                _c("b-form-checkbox", {
                                  attrs: {
                                    value: data.item.id,
                                    name: "check-button",
                                  },
                                  model: {
                                    value: _vm.selected,
                                    callback: function ($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "b-row",
                        { staticClass: "mb-2" },
                        [
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-form",
                                  { attrs: { inline: "" } },
                                  [
                                    _vm.selected.length != 0
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: { variant: "danger" },
                                            on: { click: _vm.removeSelected },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("REMOVE_SELECTED"))
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.filter_list.length,
                                  "per-page": _vm.filterLimit,
                                  "aria-controls": "my-table",
                                },
                                model: {
                                  value: _vm.currentFilterPage,
                                  callback: function ($$v) {
                                    _vm.currentFilterPage = $$v
                                  },
                                  expression: "currentFilterPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.clickedItem
        ? _c(
            "div",
            [
              _c("SDKInformation", {
                attrs: {
                  item: _vm.clickedItem,
                  functionbox_uuid: _vm.functionbox_uuid,
                },
                on: {
                  setItem: _vm.setItem,
                  selectedAddress: _vm.selectedAddress,
                  unselectAddress: _vm.unselectAddress,
                  backBtn: _vm.backBtn,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "save-as-new",
            "hide-header": "",
            centered: "",
            "cancel-title": _vm.$t("CANCEL"),
            "ok-title": _vm.$t("SAVE"),
          },
          on: { cancel: _vm.cancelSave, ok: _vm.okSave },
        },
        [
          _c("b-form-input", {
            attrs: { trim: "", placeholder: _vm.$t("NAME") },
            model: {
              value: _vm.new_filter_name,
              callback: function ($$v) {
                _vm.new_filter_name = $$v
              },
              expression: "new_filter_name",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }