var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "compose-content" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.clickedItem,
              expression: "!clickedItem",
            },
          ],
        },
        [
          _c(
            "b-form",
            { attrs: { inline: "" } },
            [
              _c(
                "b-button",
                { staticClass: "no-border", on: { click: _vm.getItems } },
                [_c("i", { staticClass: "fal fa-sync" })]
              ),
              _c("b-form-input", {
                staticClass: "searchText newline-mobile",
                attrs: { trim: "", value: "", placeholder: _vm.$t("SEARCH") },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                  },
                },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "mt-2",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.items,
              fields: _vm.fields,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "dark", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.selectedAddress(data.item.data)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("CHOOSE")))]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "secondary", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.showMe(data.item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("MORE_INFORMATION")))]
                    ),
                  ]
                },
              },
              {
                key: "cell(data.attributes.name)",
                fn: function (data) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "no-border",
                        attrs: {
                          title: _vm.$t("SDK.REMOVE_RECIPIENT"),
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeAddress(data.item)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa-sharp fa-solid fa-bookmark",
                        }),
                      ]
                    ),
                    _vm._v(
                      "\n\n\n        " + _vm._s(data.item.data.attributes.name)
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(data.item.data.relationships.parent.data.name) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(Type)",
                fn: function (data) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "no-border",
                        attrs: {
                          title: _vm.$t("SDK.REMOVE_RECIPIENT"),
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeAddress(data.item)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa-sharp fa-solid fa-bookmark",
                        }),
                      ]
                    ),
                    _vm._v(
                      "\n\n\n        " +
                        _vm._s(data.item.data.attributes.name) +
                        ",\n        " +
                        _vm._s(data.item.data.relationships.parent.data.name) +
                        "\n        "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(data.item.data.attributes.description) +
                        "\n        "
                    ),
                    _c("br"),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "dark", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.selectedAddress(data.item.data)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("CHOOSE")))]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "secondary", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.showMe(data.item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("MORE_INFORMATION")))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "b-form",
                      { attrs: { inline: "" } },
                      [
                        _vm.selected.length != 0
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: { click: _vm.removeRecipients },
                              },
                              [_vm._v(_vm._s(_vm.$t("REMOVE_SELECTED")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.items.length,
                      "per-page": _vm.limit,
                      align: "center",
                      pills: "",
                    },
                    on: { input: _vm.getItems },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  _vm.saveIdentifier = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("SDK.SAVE_WITH_IDENTIFIER")))]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "add-sdk-with-identiier",
            "hide-header": "",
            centered: "",
            "ok-only": "",
            "ok-variant": "secondary",
            "ok-title": _vm.$t("CANCEL"),
          },
          on: { ok: _vm.cancelSave },
          model: {
            value: _vm.saveIdentifier,
            callback: function ($$v) {
              _vm.saveIdentifier = $$v
            },
            expression: "saveIdentifier",
          },
        },
        [
          _c("b-form-input", {
            attrs: { trim: "", placeholder: _vm.$t("SDK.IDENTIFIER") },
            model: {
              value: _vm.search_identifier,
              callback: function ($$v) {
                _vm.search_identifier = $$v
              },
              expression: "search_identifier",
            },
          }),
          _c("b-form-input", {
            staticClass: "mt-2",
            attrs: { trim: "", placeholder: _vm.$t("ORGANISATION.TITLE") },
            model: {
              value: _vm.search_organisation,
              callback: function ($$v) {
                _vm.search_organisation = $$v
              },
              expression: "search_organisation",
            },
          }),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: {
                disabled: _vm.search_identifier == "",
                variant: "primary",
              },
              on: { click: _vm.searchReplyIdentifier },
            },
            [_vm._v(_vm._s(_vm.$t("FETCH_INFORMATION")))]
          ),
          _vm.searchItems.length != 0
            ? _c("b-table", {
                staticClass: "mt-2",
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.searchItems,
                  fields: _vm.searchFields,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(Type)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(data.item.attributes.name) +
                              ",\n        " +
                              _vm._s(data.item.relationships.parent.data.name) +
                              "\n        "
                          ),
                          _c("br"),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "dark", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveAddress(data.item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa-sharp fa-regular fa-bookmark",
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("SAVE"))),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(id)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "dark", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveAddress(data.item)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa-sharp fa-regular fa-bookmark",
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("SAVE"))),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1107890259
                ),
              })
            : _vm._e(),
          _vm.searchDone && _vm.searchItems.length == 0
            ? _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("SDK.ADD_NOT_FOUND_SEARCH")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.clickedItem
        ? _c(
            "div",
            [
              _c("SDKInformation", {
                attrs: {
                  item: _vm.clickedItem,
                  functionbox_uuid: _vm.functionbox_uuid,
                },
                on: {
                  setItem: _vm.setItem,
                  selectedAddress: _vm.selectedAddress,
                  removedAddress: _vm.removedAddress,
                  backBtn: _vm.backBtn,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }